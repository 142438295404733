// extracted by mini-css-extract-plugin
export var centeredTitle = "cS_hQ";
export var childrenContainer = "cS_hS";
export var container = "cS_f";
export var dark = "cS_dq";
export var innerContainer = "cS_cC";
export var left = "cS_cL";
export var light = "cS_dp";
export var noBottomPadding = "cS_hL";
export var noRectangle = "cS_hP";
export var noTopPadding = "cS_hM";
export var overflowHidden = "cS_hN";
export var right = "cS_cM";
export var subtitle = "cS_s";
export var tan = "cS_cH";
export var textContainer = "cS_bY";
export var textContainerCustomWidth = "cS_hR";