// extracted by mini-css-extract-plugin
export var button = "W_j";
export var children = "W_cq";
export var disabled = "W_ch";
export var fullWidth = "W_cp";
export var iconWrapper = "W_cs";
export var large = "W_cl";
export var link = "W_bS";
export var loading = "W_cg";
export var loadingContainer = "W_cr";
export var medium = "W_cm";
export var primary = "W_cf";
export var secondary = "W_cj";
export var small = "W_cn";
export var tertiary = "W_ck";