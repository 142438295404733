// extracted by mini-css-extract-plugin
export var border1 = "cW_hV";
export var border2 = "cW_hW";
export var border3 = "cW_hX";
export var border4 = "cW_hY";
export var container = "cW_f";
export var dark = "cW_dq";
export var large = "cW_cl";
export var ldsRing = "cW_hZ";
export var light = "cW_dp";
export var medium = "cW_cm";
export var primaryPressed = "cW_h0";
export var xlarge = "cW_hT";